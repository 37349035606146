<template>
  <b-modal
      id="modal-size-update"
      title="Редактирование размера"
      ok-title="Сохранить"
      @ok="save"
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="name" class="d-block">Название *</label>
          <div>
            <input id="name" v-model="form.name" placeholder="Введите название" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="from_age" class="d-block">Комментарии</label>
          <div>
            <input id="from_age" v-model="form.comments" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="ua_size" class="d-block">UA Размер</label>
          <div>
            <input id="ua_size" v-model="form.ua" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="eu_size" class="d-block">EU Размер</label>
          <div>
            <input id="eu_size" v-model="form.eu" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="eu_size" class="d-block">IT Размер</label>
          <div>
            <input id="eu_size" v-model="form.it" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="uk_size" class="d-block">UK Размер</label>
          <div>
            <input id="uk_size" v-model="form.uk" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="us_size" class="d-block">US Размер</label>
          <div>
            <input id="us_size" v-model="form.us" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="uk_size" class="d-block">CA Размер</label>
          <div>
            <input id="uk_size" v-model="form.ca" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="us_size" class="d-block">DE Размер</label>
          <div>
            <input id="us_size" v-model="form.de" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="uk_size" class="d-block">CHINA Размер</label>
          <div>
            <input id="uk_size" v-model="form.china" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="us_size" class="d-block">UNIVERSAL Размер</label>
          <div>
            <input id="us_size" v-model="form.universal" placeholder="Введите размер" class="form-control">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="form-group">
          <label for="us_size" class="d-block">Проверено</label>
          <div>
            <b-form-checkbox
              v-model="form.checked"
              class="mr-0 mt-50"
              switch
              inline
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BFormCheckbox, BModal } from 'bootstrap-vue';

export default {
  name: 'SizeUpdateModal',
  components: {
    BFormCheckbox,
    BModal,
  },
  props: ['size'],
  data() {
    return {
      form: {
        name: null,
        comments: null,
        ua: null,
        eu: null,
        uk: null,
        us: null,
        ca: null,
        de: null,
        china: null,
        universal: null,
        checked: false,
      },
    };
  },
  watch: {
    size() {
      if (this.size) {
        this.form = this.size;
        this.form.checked = this.size.checked === 1;
      }
    },
  },
  methods: {
    async save() {
      if (!this.form.name) {
        this.$bvToast.toast('Заполните все поля', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
        return false;
      }

      try {
        await this.$api.sizes.update(this.size.id, { ...this.form });
        this.$bvToast.toast('Размер успешно сохранен', {
          title: 'Успех',
          variant: 'success',
          solid: true,
        });
        await this.$emit('update');
      } catch (e) {
        this.$bvToast.toast(`Ошибка при сохранении размера ${JSON.stringify(e.response.data.details)}`, {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      }

      return true;
    },
  },
};
</script>
