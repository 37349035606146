<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <app-breadcrumb />
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="m-2">
              <div class="row">
                <div class="col col-12 col-md-6 d-flex align-items-center justify-content-start mb-1 mb-md-0">
                  <button
                      v-b-modal.modal-size-create
                      v-if="$can('manage', 'sizes')"
                      class="btn btn-primary"
                  >
                    <span class="text-nowrap">Добавить Размер</span>
                  </button>
                </div>
                <div class="col-12 col-md-6">
                  <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        v-model="searchQuery"
                        @change="getSizes" class="d-inline-block mr-1"
                        placeholder="Поиск..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ sizes.from }} по {{ sizes.to }} из {{ sizes.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="sizes.total"
                      :per-page="sizes.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <!---->
                <tr>
                  <th>#</th>
                  <th>Название</th>
                  <th>Соответствие размеров</th>
                  <th>Категории</th>
                  <th>Товары</th>
                  <th>Пользователи</th>
                  <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="size in sizes.data" :key="size.id">
                  <td>
                    <router-link :to="{name: 'size-details', params: { id: size.id }}">
                      {{ size.id }}
                    </router-link>
                  </td>
                  <td>
                    {{ size.name }}
                    <span v-if="size.comments" class="text-nowrap"><br>{{ size.comments }}</span>
                  </td>
                  <td>
                   <div class="row">
                     <div class="col-6">
                       EU: <b>{{ size.eu }}</b><br>
                       US: <b>{{ size.us }}</b><br>
                       UK: <b>{{ size.uk }}</b><br>
                       IT: <b>{{ size.it }}</b><br>
                       UA: <b>{{ size.ua }}</b>
                     </div>
                     <div class="col-6">
                       CA: <b>{{ size.ca }}</b><br>
                       DE: <b>{{ size.de }}</b><br>
                       <span class="text-nowrap">CHINA: <b>{{ size.china }}</b></span><br>
                       <span class="text-nowrap">UNIVERSAL: <b>{{ size.universal }}</b></span>
                     </div>
                   </div>
                  </td>
                  <td>{{ size.category_dictionaries_count || '-' }}</td>
                  <td>{{ size.product_specifications_count || '-' }}</td>
                  <td>{{ size.user_dictionaries_count || '-' }}</td>
                  <td>
                    <button @click="editSize(size)" class="btn btn-primary btn-block">Редактировать</button>
                    <button @click="remove(size)" class="btn btn-danger btn-block">Удалить</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ sizes.from }} по {{ sizes.to }} из {{ sizes.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="sizes.total"
                      :per-page="sizes.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <SizeCreateModal @update="getSizes"/>
    <SizeUpdateModal :size="selectedSize" @update="getSizes"/>
  </div>
</template>

<script>
import { BFormInput, BPagination } from 'bootstrap-vue';
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import SizeCreateModal from '@/views/sizes/SizeCreateModal.vue';
import SizeUpdateModal from '@/views/sizes/SizeUpdateModal.vue';

export default {
  name: 'SizeList',
  components: {
    BFormInput,
    BPagination,
    AppBreadcrumb,
    SizeCreateModal,
    SizeUpdateModal,
  },
  data() {
    return {
      currentPage: 1,
      breadcrumbs: [],
      selectedSize: null,
      sizes: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
      searchQuery: null,
    };
  },
  watch: {
    async currentPage(newValue) {
      this.sizes.current_page = newValue;
      await this.getSizes();
    },
  },
  async mounted() {
    await this.getSizes();
  },
  methods: {
    editSize(size) {
      this.selectedSize = size;
      this.$bvModal.show('modal-size-update');
    },
    remove(size) {
      this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить размер?', {
        title: 'Подтверждение удаления',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(async (value) => {
          if (value) {
            await this.$api.sizes.delete(size.id);
            this.$bvToast.toast('Размер успешно удален', {
              title: 'Успешно',
              variant: 'success',
              solid: true,
            });
            await this.getSizes();
          }
        });
    },
    async getSizes() {
      this.sizes = (await this.$api.sizes.list({
        ...(this.sizes.current_page > 1 && { page: this.sizes.current_page }),
        limit: 25,
        ...(this.searchQuery && { search: this.searchQuery }),
      })).data;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
